import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["enableTrials", "trialsDuration"];

  connect() {
    this.toggle();
  }

  toggle() {
    if (this.enableTrialsTarget.checked) {
      this.showTrialsDurationElement();
    } else {
      this.hideTrialsDurationElement();
    }
  }

  showTrialsDurationElement() {
    this.trialsDurationTarget.classList.remove("hidden");
  }

  hideTrialsDurationElement() {
    this.trialsDurationTarget.classList.add("hidden");
  }
}
