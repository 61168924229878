import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["allPlans", "warningMessage"];
  static values = { savedPlans: Array };

  hasNewPlans(savedPlans, currentPlans) {
    return currentPlans.some(plan => !savedPlans.includes(plan));
  }

  toggle() {
    const currentPlans = this.allPlansTargets.filter(plan => plan.checked).map(plan => plan.value);
    const savedPlans = this.savedPlansValue;
    const hasNewPlans = this.hasNewPlans(savedPlans, currentPlans);

    if (hasNewPlans) {
      this.showHiddenElement();
    } else {
      this.hideHiddenElement();
    }
  }

  showHiddenElement() {
    this.warningMessageTarget.classList.remove("hidden");
  }

  hideHiddenElement() {
    this.warningMessageTarget.classList.add("hidden");
  }
}
