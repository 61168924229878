import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['template', 'templateWithSuggestionOpened']

  show(e) {
    if (e) e.preventDefault()

    window.amplitude.getInstance().logEvent('[Main menu] Click Whats News')

    this.createModal(this.templateTarget.innerHTML, 'newsfeed_drawer_modal')
  }

  showWithSuggestionOpened(e) {
    if (e) e.preventDefault()

    window.amplitude.getInstance().logEvent('[Main menu] Click Whats News')

    this.createModal(this.templateWithSuggestionOpenedTarget.innerHTML, 'newsfeed_drawer_modal')
  }

  createModal(content, id, size = 'tiny') {
    document.getElementById(id)?.remove()

    const modal = document.createElement('ds-modal')
    modal.setAttribute('size', size)
    modal.setAttribute('position', 'right')
    modal.setAttribute('closable', 'true')
    modal.setAttribute('show-on-mount', 'true')
    modal.setAttribute('id', id)
    modal.setAttribute('no-background', 'true')

    modal.innerHTML = content

    this.body.appendChild(modal)

    return modal
  }

  close(e) {
    e.currentTarget.closest('ds-modal').close()
  }

  get body() {
    return document.querySelector('body')
  }
}
